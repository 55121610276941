<template>
  <div>
    <app-header></app-header>
    <v-container>
      <p class="text-h5">{{ getCompany.name }}</p>
      <!-- 期間、表示単位選択 -->
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" md="2" sm="2">
              <v-select v-model="requestItem.period" :items="periods" item-text="label" item-value="value" single-line @change="selectPeriod"></v-select>
            </v-col>
            <v-col cols="12" md="2" sm="3">
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" single-line></v-text-field>
                </template>
                <v-date-picker v-model="date" v-bind:type="type" no-title scrollable locale="jp-ja" :day-format="(date) => new Date(date).getDate()">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="5" md="2" sm="2">
              <v-select v-model="requestItem.device_group_id" :items="groupNames" item-text="device_group_name" item-value="device_group_id" single-line></v-select>
            </v-col>
            <v-col cols="3" md="1" sm="2">
              <v-btn outlined @click="getWattData" v-if="chargeLogTable" class="btn-show">
                一覧表示
              </v-btn>
              <v-btn outlined @click="getWattData" v-if="chargeLogChart" class="btn-show">
                グラフ表示
              </v-btn>
            </v-col>
            <v-col cols="3" md="1" sm="2">
              <v-row justify="center" align-content="center">
                <v-col cols="3">
                  <v-btn icon @click="chageChartMode" v-if="chargeLogTable" class="icon-show">
                    <v-icon>mdi-chart-bar</v-icon>
                  </v-btn>
                  <v-btn icon @click="chageTableMode" v-if="chargeLogChart" class="icon-show">
                    <v-icon>mdi-view-list</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn icon @click="downloadCSV" v-if="chargeLogTable" class="icon-show">
                    <v-icon>mdi-file-download</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- 期間、表示単位選択ここまで -->
      <v-row>
        <v-col cols="11">
          <!-- 使用電力量一覧 -->
          <charge-log-table v-if="chargeLogTable" :headers="headers" :items="tableItems"></charge-log-table>
          <!-- 使用電力グラフ -->
          <charge-log-chart v-if="chargeLogChart" :chartData="chartData"></charge-log-chart>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import chargeLogTable from "../components/ChargeLogTable.vue";
import chargeLogChart from "../components/ChargeLogChart.vue";
import AppHeader from "../components/AppHeader.vue";

export default {
  components: {
    AppHeader,
    chargeLogTable,
    chargeLogChart,
  },
  data() {
    return {
      // 期間選択
      periods: [
        { label: "日間", value: "day" },
        { label: "月間", value: "month" },
      ],
      // グループ選択
      groups: [{ device_group_name: "ALL", device_group_id: -1 }],

      // date picker
      //デフォルト先日
      date: new Date(new Date() - 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
      type: "date",
      menu: false,

      // コンポーネント切り替え
      chargeLogTable: true,
      chargeLogChart: false,

      // テーブル
      headerItems: [
        { text: "日時", value: "date" },
        { text: "合計（kw）", value: "sum_kw" },
      ],
      plugs: [],
      //継承用パラメータ
      headers: [],
      tableItems: [],

      // グラフ
      chartData: {
        labels: [], //date
        datasets: [],
      },

      // 電力使用量取得リクエスト
      requestItem: {
        company_id: null,
        YYYY: null,
        MM: null,
        DD: null,
        period: "day",
        device_group_id: -1,
      },
    };
  },
  methods: {
    // 期間の選択
    selectPeriod() {
      if (this.requestItem.period === "month") {
        this.type = "month";
        this.date = new Date(this.date).toISOString().substr(0, 7);
      } else {
        this.type = "date";
        this.date = new Date(this.date).toISOString().substr(0, 10);
      }
    },
    //リクエストデータ作成
    createRequest() {
      const separateDate = this.date.split("-");
      this.requestItem.YYYY = Number(separateDate[0]);
      this.requestItem.MM = Number(separateDate[1]);
      if (this.requestItem.period === "day") {
        this.requestItem.DD = Number(separateDate[2]);
      } else {
        this.requestItem.DD = null;
      }
      this.requestItem.company_id = this.getCompany.id;
    },
    // テーブルのヘッダーを設定する
    createHeader() {
      if (this.requestItem.device_group_id < 1) {
        // すべてのグループ
        this.headers = this.tableHeaderAll();
      } else {
        // 特定のグループ
        this.headers = this.tableHeaderPlug();
      }
    },
    //ALL グループ ヘッダーカラム名作成
    tableHeaderAll() {
      //const groupNames = this.getGroups.map((group) => group.device_group_name);
      //console.log(groupNames);

      const headers = this.getGroups.map((group) => ({
        text: group.device_group_name,
        value: group.device_group_name,
      }));
      return this.headerItems.concat(headers);
    },
    // 特定のグループ選択時一覧ヘッダー、プラグヘッダー作り
    tableHeaderPlug() {
      const watt = this.$store.getters["Home/getWatt"];
      if (!watt) {
        return this.headerItems;
      }
      var plug_data = Object.assign({}, watt[0]);
      //プラグ計算
      delete plug_data.date;
      delete plug_data.sum_kw;
      // for (let key in plug_data) {
      //   if (!plug_data[key]) delete plug_data[key];
      // }
      const key = Object.keys(plug_data);

      this.plugs = [];
      key.map((key) => {
        let plug = {};
        plug.value = key;
        key.substr(1);
        plug.text = "プラグ" + key;
        this.plugs.push(plug);
      });
      return this.headerItems.concat(this.plugs);
    },
    //テーブルデータ所得
    createTableItems() {
      this.tableItems = this.$store.getters["Home/getWatt"];
    },

    // グラフ表示の作成
    createChart() {
      var labels = [];
      // 日付ラベルの設定（X軸）
      if (this.tableItems) {
        labels = this.tableItems.map((log) => log.date);
      }
      // 名前の配列取得
      var names = "";
      if (this.requestItem.device_group_id < 0) {
        names = this.getGroups.map((group) => group.device_group_name);
      } else {
        names = this.plugs.map((plug) => plug.value);
      }
      var numbers = [];
      // グループ、ポート別のデータを取得して配列を作成
      for (let i = 0; i < names.length; i++) {
        const individualData = this.tableItems.map((log) => log[names[i]]);
        numbers.push(individualData);
      }
      // datasetsの作成
      var datasets = [];
      const COLORS = ["#4dc9f6", "#f67019", "#f53794", "#537bc4", "#acc236", "#166a8f", "#00a950", "#58595b", "#8549ba"];

      const Newdatasets = names.map((name, i) => ({
        label: names[i],
        backgroundColor: COLORS[i % COLORS.length],
        data: numbers[i],
        stack: "stack1",
        borderWidth: 1,
      }));
      datasets = datasets.concat(Newdatasets);
      this.chartData = { labels: labels, datasets: datasets };
    },
    // コンポーネント切り替え
    chageChartMode() {
      this.chargeLogTable = false;
      this.chargeLogChart = true;
    },
    chageTableMode() {
      this.chargeLogChart = false;
      this.chargeLogTable = true;
    },
    //API通信wattを獲得
    async getWattData() {
      this.createRequest();
      //通信
      await this.$store.dispatch("Home/getWatt", { data: this.requestItem });
      //テーブルHeader作り
      this.createHeader();
      //テーブルデータ作り
      this.createTableItems();
      //グラフ作り
      await this.createChart();
    },
    // CSVダウンロード
    async downloadCSV() {
      this.createRequest();
      await this.$store.dispatch("Home/download", { data: this.requestItem, date: this.date });
    },
  },
  computed: {
    //会社名
    getCompany() {
      return this.$store.getters["Home/getCompany"];
    },
    // 会社所有グループ
    getGroups() {
      return this.$store.getters["Home/getDevice_Groups"];
    },
    // グループ選択用リスト
    groupNames() {
      return this.groups.concat(this.getGroups);
    },
  },
  mounted() {
    //company,user,group_dataをAPIから獲得vuexに設定
    const user = JSON.parse(sessionStorage.getItem("user"));
    const id = user.id;
    this.$store.dispatch("Home/getIndex", { data: id });
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}
.btn-show{
  margin-top: 10px
}
.icon-show{
  margin-top: 15px
}
</style>
