export default {
  namespaced: true,
  state: {
    access_token: "",
    user: {},
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setUser(state, payload) {
      sessionStorage.setItem("user", JSON.stringify(payload.user));
      state.user = payload.user;
    },
    setToken(state, payload) {
      sessionStorage.setItem("token", JSON.stringify(payload.access_token));
      state.access_token = payload.access_token;
    },
    resetUser(state) {
      state.user = {};
      sessionStorage.removeItem("user");
    },
    resetToken(state) {
      state.access_token = "";
      sessionStorage.removeItem("token");
    },
  },
  actions: {
    async login({ dispatch, commit }, { data }) {
      const alert = {
        success_msg: "ログイン成功",
        error_msg: "ログイン失敗",
      };
      await dispatch("Http/post", { url: "/auth/login", data: data, alert: alert }, { root: true })
        .then((res) => {
          if(res){
            commit("setUser", { user: res.data.user });
            commit("setToken", { access_token: res.data.access_token });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async logout({ dispatch, commit }) {
      await dispatch("Http/post", { url: "/auth/logout" }, { root: true })
        .then(() => {
          commit("resetUser");
          commit("resetToken");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async refresh({ dispatch, commit }) {
      await dispatch("Http/post", { url: "/auth/refresh" }, { root: true })
        .then((res) => {
          commit("setUser", { user: res.data.user });
          commit("setToken", { access_token: res.data.access_token });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
