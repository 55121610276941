import axios from "axios";
import router from "@/router";
export default {
  namespaced: true,
  actions: {
    async request({ dispatch }, { method, url, data, alert }) {
      const headers = {};
      headers["Content-Type"] = "application/json";
      var token = JSON.parse(sessionStorage.getItem("token"));

      if (token) {
        //tokenが存在する時リフレッシュ（トークンの保有時間延長ため）
        if (url != "/auth/refresh") {
          await dispatch("Auth/refresh", null, { root: true });
          token = JSON.parse(sessionStorage.getItem("token"));
        }
        headers.Authorization = `Bearer ${token}`;
      }

      const options = {
        method,
        url: `${process.env.VUE_APP_API_URL_BASE}${url}`,
        headers,
        data,
        timeout: 15000,
      };
      if (alert) {
        alert.success_flag = true;
      }
      return axios(options)
        .then((res) => res)
        .catch((err) => {
          //error alert
          if (alert) {
            alert.error_flag = true;
            alert.success_flag = false;
            dispatch("Alert/alert", { alert: alert }, { root: true });
          }
          if (token && err.response.status === 401) {
            this.commit("Auth/resetUser");
            this.commit("Auth/resetToken");
            router.push("/login");
          }
        })
        .finally(() => {
          //success alert
          if (alert && alert.success_flag == true) {
            dispatch("Alert/alert", { alert: alert }, { root: true });
          }
        });
    },
    async get({ dispatch }, request) {
      request.method = "get";
      return dispatch("request", request);
    },
    async post({ dispatch }, request) {
      request.method = "post";
      return dispatch("request", request);
    },
    async put({ dispatch }, request) {
      request.method = "put";
      return dispatch("request", request);
    },
    async delete({ dispatch }, request) {
      request.method = "delete";
      return dispatch("request", request);
    },
  },
};
