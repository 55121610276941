export default {
  namespaced: true,
  state: {
    users: [],
    companies: [],
    clickLock: true,
  },
  getters: {
    getUsers(state) {
      return state.users;
    },
    getCompanies(state) {
      return state.companies;
    },
    getClickLock(state) {
      return state.clickLock;
    },
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload.data;
    },
    setCompanies(state, payload) {
      state.companies = payload.companies;
    },
    setClickLock(state) {
      state.clickLock = !state.clickLock;
    },
    reSetClickLock(state) {
      state.clickLock = true;
    },
  },
  actions: {
    async index({ dispatch, commit }, { url }) {
      await dispatch("Http/get", { url: url }, { root: true })
        .then((res) => {
          if(res){
            const payload_user = { data: res.data.data };
            const payload_company = { companies: res.data.companies };
            commit("setUsers", payload_user);
            commit("setCompanies", payload_company);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async create({ dispatch }, { url, data }) {
      const alert = {
        success_msg: "登録成功",
        error_msg: "登録失敗",
      };
      await dispatch("Http/post", { url: url, data: data, alert: alert }, { root: true })
        .then((res) => {
          console.log(res.data);
          if (res.data.status != "OK") {
            alert.error_flag = true;
            alert.success_flag = false;
            dispatch("Alert/alert", { alert: alert }, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async update({ dispatch }, { url, data }) {
      const alert = {
        success_msg: "更新成功",
        error_msg: "更新失敗",
      };
      await dispatch("Http/put", { url: url, data: data, alert: alert }, { root: true })
        .then((res) => {
          console.log(res.data);
          if (res.data.status != "OK") {
            alert.error_flag = true;
            alert.success_flag = false;
            dispatch("Alert/alert", { alert: alert }, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async delete({ dispatch }, { url }) {
      const alert = {
        success_msg: "削除成功",
        error_msg: "削除失敗",
      };
      await dispatch("Http/delete", { url: url, alert: alert }, { root: true })
        .then((res) => {
          if (res.data.status != "OK") {
            alert.error_flag = true;
            alert.success_flag = false;
            dispatch("Alert/alert", { alert: alert }, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
