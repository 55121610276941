<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],

  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "kw",
              },
              ticks: {
                beginAtZero: true,
                stepSize: 50,
                stacked: true,
              },
            },
          ],
        },
      },
    };
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
