<template>
  <div>
    <app-header></app-header>
    <admin-header :title="title"></admin-header>

    <v-toolbar flat>
      <!-- 顧客追加 -->
      <!-- 顧客名重複チェック後ほど8/30 -->
      <v-dialog v-model="dialog" persistent max-width="700px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            顧客追加
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="justify-center">
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="company_form">
                <v-row>
                  <v-col cols="8">
                    <v-text-field label="顧客名*" v-model="requestItem.name" filled :rules="[required, limit_length]"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field label="担当者" v-model="requestItem.staff" filled :rules="[limit_length_staff]"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="郵便番号" v-model="requestItem.post" filled :rules="[limit_length_post, halfsize_number]" hint="ハイフン（-）を除く数字のみを入力してください"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="住所" v-model="requestItem.address" filled :rules="[limit_length]"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="電話番号" v-model="requestItem.tel" filled :rules="[limit_length_tel, halfsize_number]" hint="ハイフン（-）を除く数字のみを入力してください"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              キャンセル
            </v-btn>
            <v-btn color="blue darken-1" text @click="save" :disabled="clickLock">
              {{ editSaveButton }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 顧客追加ここまで -->
      <!-- 検索 -->
      <v-col cols="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="検索" single-line hide-details class="mx-5"></v-text-field>
      </v-col>
    </v-toolbar>

    <!-- 顧客一覧表示 -->
    <v-data-table :headers="headers" :items="getCompanies" :items-per-page="10" :search="search" :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }">
      <template v-slot:[`items`]="{ item }">
        <td v-for="header in headers" :key="header.value">
          {{ item[header.value] }}
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="editClick(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteClick(item.id)" class="ml-5">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <!-- 顧客一覧表示ここまで -->

    <!-- 削除確認ダイアログ -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>本当に削除してよろしいですか？</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">キャンセル</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="clickLock">確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 削除確認ダイアログここまで -->
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AdminHeader from "../components/AdminHeader.vue";
export default {
  components: {
    AdminHeader,
    AppHeader,
  },
  data() {
    return {
      // メニュータイトル
      title: "顧客一覧",
      // 検索
      search: "",

      // テーブル
      headers: [
        { text: "", value: "actions", sortable: false },
        { text: "ID", value: "id" },
        { text: "顧客名", value: "name" },
        { text: "郵便番号", value: "post" },
        { text: "住所", value: "address" },
        { text: "電話番号", value: "tel" },
        { text: "担当者", value: "staff" },
        { text: "作成日時", value: "created_at" },
        { text: "更新日時", value: "updated_at" },
      ],
      companies: [],

      // 登録モーダル
      dialog: false,
      requestItem: {
        name: "",
        address: "",
        post: "",
        tel: "",
        staff: "",
      },
      defaultItem: {
        name: "",
        address: "",
        post: "",
        tel: "",
        staff: "",
      },

      // 削除モーダル
      dialogDelete: false,
      deleteId: null,

      // バリデーション
      required: (value) => !!value || "入力必須項目です",
      limit_length: (value) => value == null || value.length <= 255 || "255文字以下で入力してください",
      limit_length_staff: (value) => value == null || value.length <= 45 || "45文字以下で入力してください",
      limit_length_post: (value) => value == null || value.length <= 7 || "7文字以下で入力してください",
      limit_length_tel: (value) => value == null || value.length <= 11 || "11文字以下で入力してください",
      halfsize_number: (value) => value == null || /^[0-9]*$/.test(value) || "半角数字を入力してください",
    };
  },
  methods: {
    editClick(item) {
      this.requestItem = Object.assign({}, item);
      if (this.requestItem.post && this.requestItem.post.indexOf('-'))
        this.requestItem.post = this.requestItem.post.replace("-", "");
      this.dialog = true;
    },
    createRequest() {
      delete this.requestItem.created_at;
      delete this.requestItem.updated_at;
    },
    async getCompaniesIndex() {
      const url = "/admin/companies/index";
      await this.$store.dispatch("Admin/index", { url: url });
      this.$store.commit("Admin/setClickLock");
    },
    async createNewItem() {
      const url = "/admin/companies/create";
      await this.$store.dispatch("Admin/create", { url: url, data: this.requestItem, alert: alert });
    },
    async updateItem() {
      const url = "/admin/companies/update";
      await this.$store.dispatch("Admin/update", { url: url, data: this.requestItem, alert: alert });
    },
    async save() {
      if (this.$refs.company_form.validate()) {
        //通信中ボタンロック
        this.$store.commit("Admin/setClickLock");
        // リクエスト情報作成
        this.createRequest();
        // 送信
        if (!this.requestItem.id) {
          // 新規
          await this.createNewItem();
        } else {
          // 編集
          await this.updateItem();
        }
        //閉じるとリクエストデフォルト復元
        this.close();
        await this.getCompaniesIndex();
      } else {
        console.log(this.requestItem);
        console.log("バリデーションエラー！");
      }
    },
    async deleteItemConfirm() {
      // 削除情報送信
      this.$store.commit("Admin/setClickLock");

      const url = `/admin/companies/${this.deleteId}/delete`;
      await this.$store.dispatch("Admin/delete", { url: url, alert: alert });

      this.closeDelete();
      await this.getCompaniesIndex();
    },
    close() {
      this.requestItem = Object.assign({}, this.defaultItem);
      this.$refs.company_form.resetValidation();
      this.dialog = false;
    },
    deleteClick(id) {
      this.deleteId = id;
      this.dialogDelete = true;
    },

    closeDelete() {
      this.deleteId = null;
      this.dialogDelete = false;
    },
  },
  computed: {
    formTitle() {
      return "id" in this.requestItem ? "顧客編集" : "顧客新規登録";
    },
    editSaveButton() {
      return "id" in this.requestItem ? "更新" : "登録";
    },
    getCompanies() {
      return this.$store.getters["Admin/getCompanies"];
    },
    clickLock() {
      return this.$store.getters["Admin/getClickLock"];
    },
  },
  mounted() {
    this.getCompaniesIndex();
  },
};
</script>
