<template>
  <div>
    <app-header></app-header>
    <admin-header :title="title"></admin-header>

    <v-toolbar flat>
      <!-- 利用者追加 -->
      <v-dialog v-model="dialog" persistent max-width="700px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            利用者追加
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="justify-center">
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="user_form">
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="ログインID（メールアドレス）*" v-model="requestItem.login_id" filled :rules="[required, limit_length, type_email]"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="パスワード*" v-model="requestItem.password" filled :rules="[required, limit_length]"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select label="タイプ*" v-model.number="requestItem.type" :items="types" item-text="text" item-value="value" filled :rules="[select_required]" @change="selectType"></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      label="顧客名"
                      v-model.number="requestItem.company_id"
                      :items="getCompanies"
                      item-text="name"
                      item-value="id"
                      filled
                      v-bind:disabled="isAdmin"
                      :rules="[company_required]"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="電話番号" v-model="requestItem.tel" filled :rules="[limit_length_tel, halfsize_number]" hint="ハイフン（-）を除く数字のみを入力してください"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              キャンセル
            </v-btn>
            <v-btn color="blue darken-1" text @click="save" :disabled="clickLock">
              {{ editSaveButton }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 利用者追加ここまで -->
      <!-- 検索 -->
      <v-col cols="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="検索" max-width="10px" single-line hide-details class="mx-5"></v-text-field>
      </v-col>
    </v-toolbar>

    <!-- 利用者一覧表示 -->
    <v-data-table :headers="headers" :items="getUsers" :items-per-page="10" :search="search" :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }">
      <template v-slot:[`items`]="{ item }">
        <td v-for="header in headers" :key="header.value">
          {{ item[header.value] }}
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="editClick(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteClick(item.id)" class="ml-5">mdi-delete</v-icon>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <div>
          {{ getType(item.type) }}
        </div>
      </template>
    </v-data-table>
    <!-- 利用者一覧表示ここまで -->

    <!-- 削除確認ダイアログ -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>本当に削除してよろしいですか？</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">キャンセル</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="clickLock">確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 削除確認ダイアログここまで -->
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AdminHeader from "../components/AdminHeader.vue";
export default {
  components: {
    AppHeader,
    AdminHeader,
  },
  data() {
    return {
      // メニュータイトル
      title: "利用者一覧",
      // 検索
      search: "",

      // テーブル
      headers: [
        { text: "", value: "actions", sortable: false },
        { text: "ID", value: "id" },
        { text: "ログインID", value: "login_id" },
        { text: "パスワード", value: "password" },
        { text: "ユーザータイプ", value: "type" },
        { text: "顧客名", value: "company_name" },
        { text: "電話番号", value: "tel" },
        { text: "作成日時", value: "created_at" },
        { text: "更新日時", value: "updated_at" },
      ],

      // 登録モーダル
      dialog: false,
      isAdmin: true,
      types: [
        { text: "管理", value: 0 },
        { text: "契約", value: 1 },
      ],
      requestItem: {
        company_id: null,
        login_id: "",
        password: "",
        type: null,
        tel: "",
      },
      defaultItem: {
        company_id: null,
        login_id: "",
        password: "",
        type: null,
        tel: "",
      },

      // 削除モーダル
      dialogDelete: false,
      deleteId: null,

      // アラート
      success: {
        alert: false,
        message: "",
      },
      error: {
        alert: false,
        message: "",
      },

      // バリデーション
      required: (value) => !!value || "入力必須項目です",
      select_required: (value) => value != null || "選択必須項目です",
      limit_length: (value) => value == null || value.length <= 45 || "45文字以下で入力してください",
      limit_length_tel: (value) => value == null || value.length <= 11 || "11文字以下で入力してください",
      type_email: (value) => value == null || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || "メールアドレスを入力してください",
      halfsize_number: (value) => value == null || /^[0-9]*$/.test(value) || "半角数字を入力してください",
      company_required: (value) => value != null || "選択必須項目です",
    };
  },
  methods: {
    editClick(item) {
      this.requestItem = Object.assign({}, item);
      this.selectType();
      this.dialog = true;
    },
    createRequest() {
      delete this.requestItem.company_name;
      delete this.requestItem.created_at;
      delete this.requestItem.updated_at;
    },
    async getUsersIndex() {
      const url = "/admin/users/index";
      await this.$store.dispatch("Admin/index", { url: url });
      this.$store.commit("Admin/setClickLock");
    },
    async createNewItem() {
      const url = "/admin/users/create";
      await this.$store.dispatch("Admin/create", { url: url, data: this.requestItem, alert: alert });
    },
    async updateItem() {
      const url = "/admin/users/update";
      await this.$store.dispatch("Admin/update", { url: url, data: this.requestItem, alert: alert });
    },
    async save() {
      //通信中ボタンロック
      if (this.$refs.user_form.validate()) {
        this.$store.commit("Admin/setClickLock");
        // リクエスト情報作成
        this.createRequest();
        // 送信
        if (!this.requestItem.id) {
          // 新規
          await this.createNewItem();
        } else {
          // 編集
          await this.updateItem();
        }
        //閉じるとリクエストデフォルト復元
        this.close();
        await this.getUsersIndex();
      } else {
        console.log(this.requestItem);
        console.log("バリデーションエラー！");
      }
    },
    async deleteItemConfirm() {
      // 削除情報送信
      this.$store.commit("Admin/setClickLock");
      const url = `/admin/users/${this.deleteId}/delete`;
      await this.$store.dispatch("Admin/delete", { url: url, alert: alert });

      this.closeDelete();
      await this.getUsersIndex();
    },
    close() {
      this.requestItem = Object.assign({}, this.defaultItem);
      this.$refs.user_form.resetValidation();
      this.dialog = false;
    },
    deleteClick(id) {
      this.deleteId = id;
      this.dialogDelete = true;
    },

    closeDelete() {
      this.deleteId = null;
      this.dialogDelete = false;
    },

    selectType() {
      if (this.requestItem.type !== 1) {
        this.isAdmin = true;
        this.requestItem.company_id = 0;
      } else {
        this.isAdmin = false;
      }
    },
  },
  computed: {
    getType() {
      return function(value) {
        if (value && value === 1) {
          return "契約ユーザー";
        } else {
          return "管理ユーザー";
        }
      };
    },
    getUsers() {
      return this.$store.getters["Admin/getUsers"];
    },
    getCompanies() {
      return this.$store.getters["Admin/getCompanies"];
    },
    clickLock() {
      return this.$store.getters["Admin/getClickLock"];
    },
    formTitle() {
      return "id" in this.requestItem ? "利用者編集" : "利用者新規登録";
    },
    editSaveButton() {
      return "id" in this.requestItem ? "更新" : "登録";
    },
  },
  mounted() {
    this.getUsersIndex();
  },
};
</script>
