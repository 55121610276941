<template>
  <div>
    <!-- 管理者ヘッダー -->
    <v-toolbar flat>
      <v-toolbar-title class="text-h4">管理者ページ </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-toolbar-title class="text-h4">{{ title }} </v-toolbar-title>
    </v-toolbar>
    <!-- 管理者ヘッダーここまで -->
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
