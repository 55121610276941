<template>
  <div>
    <!-- App Header -->
    <v-app-bar dark app dense>
      <v-toolbar-title class="ml-5">スマチャモニター</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- admin tabs -->
      <v-toolbar flat dense v-if="isAdmin">
        <template>
          <v-tabs right class="mr-10">
            <v-tab v-for="view in views" :key="view.name" :to="view.link" :disabled="clickLock">
              {{ view.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <!-- loginした時のみ -->
      <v-btn text @click="logout" v-if="isLogin" x-large>ログアウト</v-btn>
    </v-app-bar>
    <!-- alert -->
    <div v-if="getAlert() && isAlert">
      <v-alert type="success" text dense dismissible v-if="alert.success_flag">
        {{ alert.success_msg }}
      </v-alert>
      <v-alert type="error" text dense v-if="alert.error_flag">
        {{ alert.error_msg }}
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      views: [
        { name: "利用者", link: "/admin/users" },
        { name: "顧客", link: "/admin/companies" },
        { name: "グループ", link: "/admin/groups" },
        { name: "デバイス", link: "/admin/devices" },
      ],
      alert: {},
      isAlert: true,
    };
  },
  computed: {
    isLogin() {
      const user = JSON.parse(sessionStorage.getItem("user"));
      if (user) {
        return true;
      } else return false;
    },
    isAdmin() {
      const user = JSON.parse(sessionStorage.getItem("user"));
      if (user && user.type === 0) {
        return true;
      } else return false;
    },
    clickLock() {
      //console.log(this.$store.getters["Admin/getClickLock"]);
      return this.$store.getters["Admin/getClickLock"];
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("Auth/logout");
      this.$router.push("/login");
    },
    getAlert() {
      const alert = this.$store.getters["Alert/getAlert"];
      this.alert = alert.alert;
      if (this.isAlert === false) {
        this.isAlert = true;
      }
      return this.alert;
    },
  },
  mounted() {
    this.$store.commit("Admin/reSetClickLock");
  },
  watch: {
    //一定時間アラート自動消滅
    alert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.isAlert = false;
          this.$store.dispatch("Alert/alert", {});
        }, 3000);
      }
    },
  },
};
</script>
