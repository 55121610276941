import Vue from "vue";
import Router from "vue-router";
import UserHome from "./views/Home.vue";
import Login from "./views/Login.vue";
import Devices from "./views/Devices.vue";
import Users from "./views/Users.vue";
import Companies from "./views/Companies.vue";
import Groups from "./views/DeviceGroups.vue";

Vue.use(Router);

const routes = [
  {
    path: "/login",
    component: Login,
    name: "login",
    meta: { isPublic: true },
  },

  // 利用者用ページ
  {
    path: "/",
    component: UserHome,
    name: "userHome",
  },

  // 管理者用ページ
  {
    path: "/admin/users",
    component: Users,
    name: "adminUsers",
  },
  {
    path: "/admin/companies",
    component: Companies,
    name: "adminCompanies",
  },
  {
    path: "/admin/groups",
    component: Groups,
    name: "adminGroups",
  },
  {
    path: "/admin/devices",
    component: Devices,
    name: "adminDevices",
  },

  { path: "/home" },
  { path: "*", redirect: "/" },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  const user = JSON.parse(sessionStorage.getItem("user"));

  if (to.path != "/login" && !token) {
    next({ path: "/login" });
  } else {
    next();
  }
  if (to.path == "/home" && token) {
    if (user.type == 1) {
      next({
        path: "/",
      });
    } else if (user.type == 0) {
      next({
        path: "/admin/users",
      });
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.isPublic)) {
    next();
  } else {
    if (token && user.type == 0) {
      if (to.path == "/") {
        next({
          path: "/admin/users",
        });
      } else {
        next();
      }
    } else if (token && user.type == 1) {
      next({ path: "/" });
    } else {
      next();
    }
  }
});

export default router;
