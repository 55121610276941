import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/Auth";
import Http from "./modules/Http";
import Alert from "./modules/Alert";
import Home from "./modules/Home";
import Admin from "./modules/Admin";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Http,
    Alert,
    Home,
    Admin,
  },
});
