export default {
  namespaced: true,
  state: {
    alert: {
      success_flag: false,
      error_flag: false,
      success_msg: "",
      error_msg: "",
    },
  },
  getters: {
    getAlert(state) {
      return state.alert;
    },
  },
  mutations: {
    setAlert(state, payload) {
      state.alert = payload;
    },
  },
  actions: {
    alert({ commit }, payload) {
      commit("setAlert", payload);
    },
  },
};
