<template>
  <div>
    <!-- テーブルの表示 -->
    <v-data-table :headers="headers" :items="items" hide-default-footer disable-pagination dense> </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    headers: Array,
    items: Array,
  },
  data() {
    return {
      //
    };
  },
};
</script>
