<template>
  <div>
    <app-header></app-header>
    <v-container fill-height>
      <v-row justify="center" class="mt-16">
        <v-card width="800px" elevation="0">
          <v-card-title class="text-h4 justify-center">ログイン</v-card-title>
          <v-card-text>
            <v-container>
              <v-form>
                <v-row justify="center">
                  <v-col cols="8">
                    <v-text-field outlined v-model="user.login_id" label="メールアドレス" clearable></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      label="パスワード"
                      autocomplete
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show ? 'text' : 'password'"
                      v-model="user.password"
                      @click:append="show = !show"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" width="600px" rounded x-large depressed class="mb-15" @click="login">
              ログイン
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";

export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      user: {},
      show: false,
    };
  },
  methods: {
    async login() {
      await this.$store.dispatch("Auth/login", { data: this.user });
      this.$router.push("/home");
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}
</style>
