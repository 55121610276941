<template>
  <div>
    <app-header></app-header>
    <admin-header :title="title"></admin-header>
    <v-toolbar flat>
      <!-- 検索 -->
      <v-col cols="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="検索" single-line hide-details class="mx-5"></v-text-field>
      </v-col>
    </v-toolbar>

    <!-- グループ一覧表示 -->
    <v-data-table :headers="headers" :items="getGroups" :items-per-page="10" :search="search" :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }">
      <template v-slot:[`items`]="{ item }">
        <td v-for="header in headers" :key="header.value">
          {{ item[header.value] }}
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="editClick(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteClick(item.id)" class="ml-5">mdi-delete</v-icon>
      </template>
      <template v-slot:[`item.setting`]="{ item }">
        <div @click="clickDetail(item.setting)">
          {{ shortSetting(item.setting) }}
        </div>
      </template>
    </v-data-table>
    <!-- グループ一覧表示ここまで -->

    <!-- グループ情報編集モーダル -->
    <v-dialog v-model="dialogEdit" max-width="700px">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5">グループ情報の編集</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-text-field label="顧客名" v-model="requestItem.company_name" filled readonly></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="グループ名" v-model="requestItem.group_name" filled readonly></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="親機製造シリアル" v-model="requestItem.device_serial" filled readonly></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea name="input-7-4" auto-grow rows="10" row-height="25" label="設定データ" v-model="requestItem.setting" filled></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea name="input-7-4" auto-grow rows="2" row-height="25" label="プラグ割り当て" v-model="requestItem.plug_info" filled></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> キャンセル </v-btn>
          <v-btn color="blue darken-1" text @click="save" :disabled="clickLock"> 更新 </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- グループ情報編集モーダルここまで -->

    <!-- 削除確認ダイアログ -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>本当に削除してよろしいですか？</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">キャンセル</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="clickLock">確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 削除確認ダイアログここまで -->

    <!-- 設定データ詳細モーダル -->
    <v-dialog width="600" v-model="settingDetail">
      <template>
        <v-card>
          <v-card-title>
            設定データ
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDetail">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="mb-3"></v-divider>
          <v-card-text style="white-space: pre-wrap" v-text="this.settingDetailText"></v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <!-- 設定データ詳細モーダルここまで -->
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AdminHeader from "../components/AdminHeader.vue";
export default {
  components: {
    AdminHeader,
    AppHeader,
  },
  data() {
    return {
      // メニュータイトル
      title: "グループ一覧",
      // 検索
      search: "",

      // テーブル
      headers: [
        { text: "", value: "actions", sortable: false },
        { text: "ID", value: "id" },
        { text: "顧客名", value: "company_name" },
        { text: "グループ名", value: "group_name" },
        { text: "親機", value: "device_serial" },
        { text: "設定データ", value: "setting" },
        { text: "プラグ割り当て", value: "plug_info" },
        { text: "作成日時", value: "created_at" },
        { text: "更新日時", value: "updated_at" },
      ],
      groups: [],

      // 編集モーダル
      dialogEdit: false,
      requestItem: {
        id: null,
        group_name: "",
        company_id: null,
        company_name: "",
        device_id: null,
        device_serial: "",
        setting: "",
        plug_info: "",
      },
      defaultItem: {
        id: null,
        group_name: "",
        company_id: null,
        company_name: "",
        device_id: null,
        device_serial: "",
        setting: "",
        plug_info: "",
      },

      // 削除モーダル
      dialogDelete: false,
      deleteId: null,

      // 設定データ詳細モーダル
      settingDetail: false,
      settingDetailText: "",
    };
  },
  methods: {
    createRequest() {
      this.requestItem.name = this.requestItem.group_name;
      delete this.requestItem.group_name;
      delete this.requestItem.company_name;
      delete this.requestItem.device_id;
      delete this.requestItem.device_serial;
      console.log(this.requestItem);
    },
    editClick(item) {
      this.requestItem = Object.assign({}, item);
      this.dialogEdit = true;
    },
    async getGroupsIndex() {
      // 仮データ
      const url = "/admin/device_groups/index";
      await this.$store.dispatch("Admin/index", { url: url });
      this.$store.commit("Admin/setClickLock");
    },
    async save() {
      this.$store.commit("Admin/setClickLock");
      const url = "/admin/device_groups/update";
      await this.$store.dispatch("Admin/update", { url: url, data: this.requestItem });
      this.close();
      await this.getGroupsIndex();
    },
    async deleteItemConfirm() {
      // 削除情報送信
      this.$store.commit("Admin/setClickLock");
      const url = `/admin/device_groups/${this.deleteId}/delete`;
      await this.$store.dispatch("Admin/delete", { url: url });
      this.closeDelete();
      await this.getGroupsIndex();
    },
    close() {
      this.requestItem = Object.assign({}, this.defaultItem);
      this.dialogEdit = false;
    },
    deleteClick(id) {
      this.deleteId = id;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.deleteId = null;
      this.dialogDelete = false;
    },
    clickDetail(value) {
      this.settingDetailText = value;
      this.settingDetail = true;
    },
    closeDetail() {
      this.settingDetail = false;
    },
  },
  computed: {
    shortSetting() {
      return function(value) {
        if (value) {
          if (value.length > 20) {
            return value.substr(0, 20) + "...";
          } else {
            return value;
          }
        }
        return;
      };
    },
    getGroups() {
      return this.$store.getters["Admin/getUsers"];
    },
    clickLock() {
      return this.$store.getters["Admin/getClickLock"];
    },
  },
  mounted() {
    this.getGroupsIndex();
  },
};
</script>
