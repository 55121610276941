import fileDownload from "js-file-download";

export default {
  namespaced: true,
  state: {
    company: {},
    user: "",
    device_group_data: [],
    watt: [],
    plugs: [],
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getCompany(state) {
      return state.company;
    },
    getDevice_Groups(state) {
      return state.device_group_data;
    },
    getWatt(state) {
      return state.watt;
    },
  },
  mutations: {
    setIndex(state, payload) {
      state.company = payload.company;
      state.user = payload.user;
      state.device_group_data = payload.device_group_data;
    },
    setWatt(state, payload) {
      state.watt = payload;
    },
  },
  actions: {
    async getIndex({ commit, dispatch }, { data }) {
      const alert = {
        success_msg: "データ獲得成功",
        error_msg: "データ失敗",
      };
      await dispatch("Http/get", { url: `/user/${data}/index`, alert: alert }, { root: true })
        .then((res) => {
          const payload = { company: res.data.company, user: res.data.user, device_group_data: res.data.device_group_data };
          commit("setIndex", payload);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getWatt({ commit, dispatch }, { data }) {
      const alert = {
        success_msg: "選択データ獲得成功",
        error_msg: "選択データ獲得失敗",
      };
      await dispatch("Http/post", { url: `/user/watt`, data: data, alert: alert }, { root: true })
        .then((res) => {
          const payload = res.data.data;
          commit("setWatt", payload);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async download({ state,dispatch }, { data, date }) {
      const alert = {
        success_msg: "ダウンロード成功",
        error_msg: "ダウンロード失敗",
      };
      var filename = ''
      if (data.device_group_id != -1){
        for (let index = 0; index < state.device_group_data.length; index++) {
          if (data.device_group_id == state.device_group_data[index].device_group_id)
            filename = state.device_group_data[index].device_group_name
        }
      }
      await dispatch("Http/post", { url: `/user/download`, data: data, alert: alert }, { root: true })
        .then((res) => {
          console.log(res.data);
          fileDownload(res.data, `${date + ' ' + filename}.csv`);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
