<template>
  <div>
    <app-header></app-header>
    <admin-header :title="title"></admin-header>

    <v-toolbar flat>
      <!-- デバイス追加 -->
      <v-dialog v-model="dialog" persistent max-width="900px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            デバイス追加
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="justify-center">
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="device_form">
                <v-card-subtitle>
                  <span class="text-h6">デバイス</span>
                </v-card-subtitle>
                <v-row>
                  <v-col cols="4">
                    <v-text-field label="製造シリアル*" v-model="requestItem.producted_serial" filled :rules="[required, limit_length]"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field label="製造元" v-model="requestItem.producted_from" filled :rules="[limit_length]"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field label="製造日" v-model="requestItem.producted_date" filled type="date"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="装置バージョン" v-model="requestItem.device_version" filled :rules="[limit_length_version]"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="CLPTバージョン" v-model="requestItem.cplt_version" filled :rules="[limit_length_version]"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="CLPTFWバージョン" v-model="requestItem.cpltfw_version" filled :rules="[limit_length_version]"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="制御APバージョン" v-model="requestItem.ap_version" filled :rules="[limit_length_version]"></v-text-field>
                  </v-col>
                </v-row>
                <v-card-subtitle>
                  <span class="text-h6">グループ</span>
                </v-card-subtitle>
                <v-row>
                  <v-radio-group row v-model="requestItem.type">
                    <v-radio v-for="item in types" :key="item.text" :label="item.text" :value="item.value" :disabled="item.disabled" class="ml-5"></v-radio>
                  </v-radio-group>
                </v-row>
                <v-row v-if="requestItem.type === '0'">
                  <v-col cols="4">
                    <v-select
                      label="顧客名"
                      v-model="requestItem.company_id"
                      :items="getCompanies"
                      item-text="name"
                      item-value="id"
                      filled
                      :rules="[select_required]"
                      :disabled="companyDisabled"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field label="グループ名" v-model="requestItem.device_group_name" filled :rules="[limit_length_version]"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field label="設置場所" v-model="requestItem.location" filled :rules="[limit_length]"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="requestItem.type === '1'">
                  <v-col cols="4">
                    <v-select label="顧客名" v-model="requestItem.company_id" :items="getCompanies" item-text="name" item-value="id" filled :rules="[select_required]"></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select label="グループ" v-model="requestItem.device_group_id" :items="groupList" item-text="name" item-value="id" filled :rules="[select_required]"></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              キャンセル
            </v-btn>
            <v-btn color="blue darken-1" text @click="save" :disabled="clickLock">
              {{ editSaveButton }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- デバイス追加ここまで -->
      <!-- 検索 -->
      <v-col cols="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="検索" single-line hide-details class="mx-5"></v-text-field>
      </v-col>
    </v-toolbar>

    <!-- デバイス一覧表示 -->
    <v-data-table :headers="headers" :items="getDevices" :items-per-page="10" :search="search" :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }">
      <template v-slot:[`items`]="{ item }">
        <td v-for="header in headers" :key="header.value">
          {{ item[header.value] }}
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="editClick(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteClick(item.id)" class="ml-5">mdi-delete</v-icon>
      </template>
      <template v-slot:[`item.producted_date`]="{ item }">
        <div>{{ shortStr(item.producted_date) }}</div>
      </template>
      <template v-slot:[`item.company_name`]="{ item }">
        <div>
          {{ noData(item.company_name) }}
        </div>
      </template>
      <template v-slot:[`item.device_group_name`]="{ item }">
        <div>
          {{ noData(item.device_group_name) }}
        </div>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <div>
          {{ getType(item.type) }}
        </div>
      </template>
      <template v-slot:[`item.location`]="{ item }">
        <div>
          {{ noData(item.location) }}
        </div>
      </template>
    </v-data-table>
    <!-- デバイス一覧表示ここまで -->

    <!-- 削除確認ダイアログ -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>本当に削除してよろしいですか？</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">キャンセル</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="clickLock">確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 削除確認ダイアログここまで -->
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AdminHeader from "../components/AdminHeader.vue";
export default {
  components: {
    AdminHeader,
    AppHeader,
  },
  data() {
    return {
      // メニュータイトル
      title: "デバイス一覧",
      // 検索
      search: "",
      // テーブル
      headers: [
        { text: "", value: "actions", sortable: false },
        { text: "ID", value: "id" },
        { text: "製造シリアル", value: "producted_serial" },
        { text: "製造元", value: "producted_from" },
        { text: "製造日", value: "producted_date" },
        { text: "装置 Ver.", value: "device_version" },
        { text: "CLPT Ver.", value: "cplt_version" },
        { text: "CLPTFW Ver.", value: "cpltfw_version" },
        { text: "制御AP Ver.", value: "ap_version" },
        { text: "顧客名", value: "company_name" },
        { text: "グループ", value: "device_group_name" },
        { text: "親子", value: "type" },
        { text: "設置場所", value: "location" },
        { text: "作成日時", value: "created_at" },
        { text: "更新日時", value: "updated_at" },
      ],
      // 登録モーダル
      dialog: false,
      types: [
        { text: "親", value: "0", disabled: false },
        { text: "子", value: "1", disabled: false },
        { text: "未定", value: "-1", disabled: false },
      ],
      //companies: [],
      companyDisabled: false,
      requestItem: {
        type: "-1",
        company_id: null,
        device_group_id: null,
        device_group_name: "",
        location: "",
        device_version: "",
        cplt_version: "",
        cpltfw_version: "",
        ap_version: "",
        producted_from: "",
        producted_serial: "",
        producted_date: "",
      },
      defaultItem: {
        type: "-1",
        company_id: null,
        device_group_id: null,
        device_group_name: "",
        location: "",
        device_version: "",
        cplt_version: "",
        cpltfw_version: "",
        ap_version: "",
        producted_from: "",
        producted_serial: "",
        producted_date: "",
      },
      // 削除モーダル
      dialogDelete: false,
      deleteId: null,
      // バリデーション
      required: (value) => !!value || "入力必須項目です",
      select_required: (value) => value != null || "選択必須項目です",
      limit_length: (value) => value == null || value.length <= 255 || "255文字以下で入力してください",
      limit_length_version: (value) => value == null || value.length <= 45 || "45文字以下で入力してください",
    };
  },
  methods: {
    editClick(item) {
      this.requestItem = Object.assign({}, item);
      // 製造日の修正
      if (this.requestItem.producted_date) {
        let date = this.requestItem.producted_date;
        date = date.slice(0, 10);
        this.requestItem.producted_date = date;
      }

      // ラジオボタンのデータを反映
      if (this.requestItem.type == null) {
        this.requestItem.type = "-1";
      } else {
        this.requestItem.type = String(this.requestItem.type);
      }
      if (this.requestItem.type === "0") {
        // 親機の場合
        // ラジオボタンの無効化
        const disableTypes = this.types.map((type, i) => ({
          text: this.types[i].text,
          value: this.types[i].value,
          disabled: true,
        }));
        this.types = disableTypes;
        // 顧客名の無効化
        this.companyDisabled = true;
      } else if (this.requestItem.type === "1" || this.requestItem.type === "-1") {
        // 子機または未定の場合
        // ラジオボタンの親機無効化
        const target = this.types.find((type) => {
          return type.value === "0";
        });
        target.disabled = true;
      }
      this.dialog = true;
    },
    async getDevicesIndex() {
      const url = "/admin/devices/index";
      await this.$store.dispatch("Admin/index", { url: url });
      this.$store.commit("Admin/setClickLock");
    },
    createRequest() {
      // タイプの型修正
      this.requestItem.type = Number(this.requestItem.type);
      if (this.requestItem.type == -1) {
        this.requestItem.type = null;
      }
      //送信内容の編集
      //子機
      if (this.requestItem.type == 1 || this.requestItem.type == null) {
        this.requestItem.device_group_name = "";
        this.requestItem.location = "";
        if (this.requestItem.type == null) {
          this.requestItem.company_id = null;
          this.requestItem.device_group_id = null;
        }
      }
      // 親機でグループ名が設定されていないとき（製造シリアル + "のグループ"）
      if (this.requestItem.type == 0 && this.requestItem.device_group_name == "") {
        this.requestItem.device_group_name = `${this.requestItem.producted_serial}のグループ`;
      }
      // 不要な情報の削除
      delete this.requestItem.company_name;
      delete this.requestItem.created_at;
      delete this.requestItem.updated_at;
    },
    // 新規
    async createItem() {
      const url = "/admin/devices/create";
      await this.$store.dispatch("Admin/create", { url: url, data: this.requestItem });
    },
    async updateItem() {
      const url = "/admin/devices/update";
      await this.$store.dispatch("Admin/update", { url: url, data: this.requestItem });
    },
    async save() {
      if (this.$refs.device_form.validate()) {
        this.$store.commit("Admin/setClickLock");
        this.createRequest();
        // 送信
        if (!this.requestItem.id) {
          // 新規
          await this.createItem();
        } else {
          // 編集
          await this.updateItem();
        }
        this.close();
        await this.getDevicesIndex();
      } else {
        console.log(this.requestItem);
        console.log("バリデーションエラー！");
      }
    },
    async deleteItemConfirm() {
      this.$store.commit("Admin/setClickLock");
      // 削除情報送信
      const url = `/admin/devices/${this.deleteId}/delete`;
      await this.$store.dispatch("Admin/delete", { url: url });
      //閉じる
      this.closeDelete();
      this.getDevicesIndex();
    },
    close() {
      this.requestItem = Object.assign({}, this.defaultItem);
      // ラジオボタンの表示を初期設定に戻す
      const defaultTypes = this.types.map((type, i) => ({
        text: this.types[i].text,
        value: this.types[i].value,
        disabled: false,
      }));
      this.types = defaultTypes;
      // 顧客名を有効化
      this.companyDisabled = false;
      this.$refs.device_form.resetValidation();
      this.dialog = false;
    },

    deleteClick(id) {
      this.deleteId = id;
      this.dialogDelete = true;
    },

    closeDelete() {
      this.deleteId = null;
      this.dialogDelete = false;
    },
  },
  computed: {
    formTitle() {
      return "id" in this.requestItem ? "デバイス・グループ編集" : "デバイス・グループ登録";
    },
    editSaveButton() {
      return "id" in this.requestItem ? "更新" : "登録";
    },
    shortStr() {
      return function(value) {
        if (value) return value.slice(0, 10);
        return;
      };
    },
    getType() {
      return function(value) {
        if (value == null) {
          return "-";
        }
        if (value && value === 1) {
          return "子";
        } else {
          return "親";
        }
      };
    },
    noData() {
      return function(value) {
        if (value == null || value == "") {
          return "-";
        } else {
          return value;
        }
      };
    },
    groupList() {
      if (this.requestItem.company_id) {
        const target = this.getCompanies.find((company) => {
          return company.id === this.requestItem.company_id;
        });
        if (target.groups) {
          return target.groups;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    getDevices() {
      return this.$store.getters["Admin/getUsers"];
    },
    getCompanies() {
      return this.$store.getters["Admin/getCompanies"];
    },
    clickLock() {
      return this.$store.getters["Admin/getClickLock"];
    },
  },
  mounted() {
    this.getDevicesIndex();
  },
};
</script>
